import { NoDataIcon } from "modules/WebBuilder-V2/Components/Content/OurServices/icons";
import React from "react";

import ResultItem from "./resultItem";
import Locale from "translations";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useSBSState } from "context/global";

export default function SearchResultData({
  resultData,
  productType,
  filter,
  setFilter,
  handleSort,
  searchCountryId
}) {
  const { webBuilder } = Locale;
  const { allCountries, locale } = useSBSState();




  return (
    <div className="search-result">
      <div className="header">
        <p>
          {resultData?.length > 0
            ? `${allCountries.find(country => +country?.id === +searchCountryId)?.name || resultData[0].locationName?.[locale] || resultData[0].locationName} - ${productType?.name}`
            : null}
        </p>
        <div className="select-box">
          <p>{webBuilder.sortBy}</p>
          <select
            className="sort-price-select"
            placeholder="Best Price"
            onChange={(e) => {
              setFilter({ ...filter, sortBy: e.target.value })
              handleSort(e.target.value)
            }}
          >
            {/* <option value="null">Best Price</option> */}
            <option value="asc" selected={filter?.sortBy === "asc"}> {webBuilder.lowestPrice}</option>
            <option value="desc" selected={filter?.sortBy === "desc"}>{webBuilder.highestPrice}</option>
          </select>
        </div>
      </div>
      <div className="result">
        {resultData?.length > 0 ? (
          resultData?.map((item, index) => {
            if (productType.id === "flights") {
              return (
                <div className="web-builder-content-flights theme_flight_section w-100 wb-flights">
                  <div className="flights-cards-container">
                    <ResultItem
                      key={item?.id || index}
                      item={item}
                      productType={productType}
                    />
                  </div>
                </div>
              );
            } else {

              return (
                <ResultItem
                  key={item?.id || index}
                  item={item}
                  productType={productType}
                />
              );
            }
          })
        ) : (
          <div className="no-result">
            <NoDataIcon />
            <h6>{webBuilder.noResultsFound}</h6>
            <p>{webBuilder.youCanTryAnotherSearch}</p>
            <Link className="btn" to="/">
              {webBuilder.backtoSearch}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
